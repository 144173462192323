
import {
    ref, reactive, PropType, watch, toRefs, defineComponent
} from 'vue';
import PanelList from '@/components/common/panel-list';
import {
    computedPriceSubscription
} from '@/api/office/subscription';
import { CommunitySubscriptionUsers } from '@/api/community/subscription';
import { ElMessageBox } from 'element-plus';
import { autoPayPriceTitle } from '@/constant/field-map';

export default defineComponent({
    props: {
        selectList: {
            type: Array as PropType<CommunitySubscriptionUsers['row']>,
            default: () => ([]),
            require: true
        },
        autoRenewInfo: {
            type: Object as PropType< {
                Users: string;
                IntervalType: number;
                Cycles: number;
                StartTime: string;
                TotalPrice: string;
                SubscriptionUUID: string;
                InstallerUUID: string;
            }>,
            default: () => ({}),
            require: true
        },
        // dis、subdis需要传递InstallerUUID
        installerUUID: {
            type: String,
            default: ''
        },
        opType: {
            type: String,
            default: 'add'
        }
    },
    components: {
        PanelList
    },
    setup(props, { emit }) {
        const panelListColumn = reactive([
            { prop: 'IntervalType', label: WordList.RenewType, show: true },
            // { prop: 'StartTime', label: WordList.TmpKeyManageListTanleBeginTime, show: true },
            { prop: 'Cycles', label: WordList.RenewTimes, show: true },
            { prop: 'number', label: WordList.NumberOfRenewalUser, show: true },
            { prop: 'TotalPrice', label: WordList.TotalPriceByMonth, show: true }
        ]);
        const date = new Date();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const curYMD = `${date.getFullYear()}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;

        const formData = reactive({
            IntervalType: 0,
            StartTime: curYMD,
            Cycles: 0,
            TotalPrice: '',
            Users: [] as string[],
            SubscriptionUUID: '',
            InstallerUUID: ''
        });

        // 初始化
        formData.IntervalType = toRefs(props.autoRenewInfo).IntervalType.value;
        formData.StartTime = toRefs(props.autoRenewInfo).StartTime.value;
        formData.Cycles = toRefs(props.autoRenewInfo).Cycles.value;
        formData.TotalPrice = toRefs(props.autoRenewInfo).TotalPrice.value;
        formData.Users = JSON.parse(toRefs(props.autoRenewInfo).Users.value);
        formData.SubscriptionUUID = toRefs(props.autoRenewInfo).SubscriptionUUID.value;
        formData.InstallerUUID = toRefs(props.autoRenewInfo).InstallerUUID.value;

        watch(() => formData, () => {
            emit('update:autoRenewInfo', {
                Users: JSON.stringify(formData.Users),
                IntervalType: formData.IntervalType,
                Cycles: formData.Cycles,
                StartTime: formData.StartTime,
                TotalPrice: formData.TotalPrice,
                SubscriptionUUID: formData.SubscriptionUUID,
                InstallerUUID: formData.InstallerUUID
            });
        }, { deep: true });

        // Auto Renew类型转化
        function changeIntervalType() {
            if (formData.IntervalType === 100) {
                panelListColumn.forEach((item, index) => {
                    if (index > 0) {
                        panelListColumn[index].show = false;
                    }
                });
            } else {
                panelListColumn.forEach((item, index) => {
                    panelListColumn[index].show = true;
                });
                if (props.selectList.length > 0) {
                    computePrice();
                }
                const index = panelListColumn.findIndex((item) => item.prop === 'TotalPrice');
                // @ts-ignore
                panelListColumn[index].label = autoPayPriceTitle[formData.IntervalType];
            }
        }

        // startTime改变
        const startTimeComparisonTips = ref('');
        function startTimeChange() {
            if (`${formData.StartTime}` === 'null') {
                formData.StartTime = curYMD;
            }
            let expiredBeforeStartTime = false;
            let expiredAfterStartTimeAndLowThree = false;
            props.selectList.forEach((item) => {
                // 开始扣费时间之前有APT已经过期
                const cond1 = new Date(formData.StartTime).getTime() > new Date(item.ExpireTime.split(' ')[0]).getTime();
                // 所选日期不是当天(过期当天)
                // const cond2 = new Date(formData.StartTime).getTime() !== new Date(curYMD).getTime();
                const cond2 = new Date(formData.StartTime).getTime() !== new Date(item.ExpireTime.split(' ')[0]).getTime();
                if (cond1 && cond2) {
                    expiredBeforeStartTime = true;
                }

                const cond3 = new Date(formData.StartTime).getTime() < new Date(item.ExpireTime.split(' ')[0]).getTime();
                // @ts-ignore
                const timeDifference = new Date(item.ExpireTime.split(' ')[0]) - new Date(formData.StartTime);
                const secondsDifference = Math.abs(timeDifference) / 1000;
                const cond4 = secondsDifference <= 259200;

                // 所选APT的最早过期时间大于开始时间不超过3天
                if (cond3 && cond4) {
                    expiredAfterStartTimeAndLowThree = true;
                }
            });
            if (expiredBeforeStartTime) {
                startTimeComparisonTips.value = WordList.SomeAPTsExpiredBeforeStartTime;
            } else if (expiredAfterStartTimeAndLowThree) {
                startTimeComparisonTips.value = WordList.ExpiredAfterStartTimeAndLowThree;
            } else {
                startTimeComparisonTips.value = '';
            }
        }
        // 配置过期时间
        function setDisabledStartDate(dateObject: Date) {
            const m = dateObject.getMonth() + 1;
            const d = dateObject.getDate();
            const dYMD = `${dateObject.getFullYear()}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;
            console.log('dYMD', dYMD);

            return new Date(dYMD).getTime() < new Date(curYMD).getTime();
        }

        watch(() => props.selectList, (newVal) => {
            if (formData.IntervalType === 100) {
                return;
            }
            // 勾选列表时候判断开始时间与所选的过期时间顺序
            startTimeChange();
            // 勾选列表时候如果有已经被自动续费的弹窗提示
            // const hasSubscriptionList: string[] = [];
            formData.Users = [];
            newVal.forEach((item) => {
                formData.Users.push(item.ID);
                // if (item.HasSubscription === 1 && props.opType === 'add') {
                //     hasSubscriptionList.push(item.Name);
                // }
            });
            // const hasSubscriptionNames = hasSubscriptionList.join(',');
            // if (hasSubscriptionNames !== '') {
            //     emit('getAlreadHasSubscription', true);
            //     ElMessageBox.confirm(
            //         `${WordList.HaveActivatedTheAutomaticPayServiceTips}:${hasSubscriptionNames}`,
            //         '',
            //         {
            //             confirmButtonText: WordList.TabelFootSure,
            //             type: 'warning',
            //             showCancelButton: false
            //         }
            //     );
            // } else {
            //     emit('getAlreadHasSubscription', false);
            //     // 计算 TotalPrice
            //     computePrice();
            // }

            // 计算 TotalPrice
            computePrice();
        }, { deep: true });
        // 计算 TotalPrice的进度状态
        const computePriceLoading = ref(false);
        // 计算 TotalPrice
        function computePrice() {
            computePriceLoading.value = true;
            emit('update:autoRenewPendding', true);
            const ids = props.selectList.map((item) => item.ID);
            const params: Record<string, any> = { Users: JSON.stringify(ids), IntervalType: formData.IntervalType };
            if (props.opType === 'edit') {
                params.SubscriptionUUID = formData.SubscriptionUUID;
            }
            computedPriceSubscription(
                params,
                (res: any) => {
                    formData.TotalPrice = res.data.Price;
                    computePriceLoading.value = false;
                    emit('update:autoRenewPendding', false);
                },
                () => {
                    computePriceLoading.value = true;
                    emit('update:autoRenewPendding', true);
                }
            );
        }

        const cyclesOptions = ref([{
            label: WordList.Unlimited,
            value: 0
        }]);
        const numberCollect = Array.from({ length: 180 }, (_, index) => ({ label: `${index + 1}`, value: index + 1 }));
        cyclesOptions.value = [{ label: WordList.Unlimited, value: 0 }].concat(numberCollect);
        const isDevOrTest = process.env.NODE_ENV === 'development'
        || window.location.href.indexOf('dev.akuvox') > -1
        || window.location.href.indexOf('test.akuvox') > -1;
        return {
            panelListColumn,
            formData,
            changeIntervalType,
            startTimeComparisonTips,
            startTimeChange,
            setDisabledStartDate,
            computePrice,
            cyclesOptions,
            numberCollect,
            computePriceLoading,
            isDevOrTest
        };
    }
});

